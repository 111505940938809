@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'tailwindcss/variants';

/* purgecss start ignore */
html,

/* custom spinner */

.spinner {
  animation: spin 1s ease infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* remove input border, no se quitaba con tw */
input:hover,
input:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
}

/* carousel */
.carousel-img {
  transition: 1s;
}

.carousel-present {
  left: 0;
  opacity: 1;
  visibility: visible;
  position: absolute;
}

.carousel-next {
  position: absolute;
  left: 0vw;

  opacity: 0;
  visibility: hidden;
  animation: fadeIn 5s;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: #214497;
  position: fixed;
  z-index: 1060;
  top: 0;
  left: 0;

  width: 100%;
  height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #214497,
    0 0 5px #214497;
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: #214497;
  border-left-color: #214497;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* select search */

.ss-options {
  list-style: none;
  width: 100%;
  background: #ffff;
}

.ss-row {
  display: block;
  width: 100%;
}

.ss-option {
  display: block;
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s background;
  outline: none;
}

.ss-option.is-selected {
  background: rgb(103, 121, 225);
  color: #fff;
}

.ss-container {
  --ss-background: #fff;
  --ss-border: #dce0e8;
  --ss-selected: #1e66f5;
  --ss-text: #000;
  --ss-subtle-text: #6c6f85;
  --ss-inverted-text: var(--ss-background);
  --ss-highlight: #eff1f5;

  width: 100%;
  position: relative;
  color: var(--ss-text);
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.05);
  border: 2px solid transparent;
  overflow: hidden;
  transition: 0.3s border-color;
  width: 100%;
}

.ss-container *,
.ss-container *::after,
.ss-container *::before {
  box-sizing: inherit;
}

.ss-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--ss-background);
  border: 1px solid var(--ss-border);
  color: var(--ss-text);
  border-radius: 6px;
  outline: none;
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.ss-input::-webkit-search-decoration,
.ss-input::-webkit-search-cancel-button,
.ss-input::-webkit-search-results-button,
.ss-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.ss-input[readonly] {
  cursor: pointer;
}

.ss-is-disabled .ss-input {
  cursor: not-allowed;
}

.ss-container:not(.ss-is-disabled).ss-has-focus .ss-input,
.ss-container:not(.ss-is-disabled) .ss-input:hover {
  border-color: var(--ss-selected);
}

.ss-select {
  background: var(--ss-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  /* border: 2px solid var(--ss-border); */
  overflow: auto;
  max-height: 360px;
}

.ss-container .ss-select {
  /* position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0; */
  border-radius: 3px;
  display: none;
}

.ss-container.ss-has-focus .ss-select {
  display: block;
}

.ss-options {
  list-style: none;
}

.ss-option,
.ss-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--ss-background);
  border: none;
  outline: none;
  color: var(--ss-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.ss-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.ss-is-highlighted,
.ss-option:not(.ss-is-selected):hover {
  background: var(--ss-highlight);
}

.ss-is-selected {
  font-weight: bold;
  color: var(--ss-selected);
}

.ss-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--ss-border);
  color: var(--ss-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.ss-row:not(:first-child) .ss-group-header {
  margin-top: 10px;
}

.ss-row:not(:last-child) .ss-group-header {
  margin-bottom: 10px;
}

/* purgecss end ignore */

/* Scrollbar */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
*::-webkit-scrollbar-track {
  background-color: #dedede;
  border-radius: 10px;
}
*::-webkit-scrollbar-thumb {
  background-color: #3375b4;
  border-radius: 14px;
}